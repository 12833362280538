import React, { useState,useEffect } from 'react'

import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Swal from 'sweetalert2';

const Assiglist = () => {


    
const [groupname,setGroup] = useState()
const [euserid,seteuserid] = useState()
const [posts, setAllPosts] = useState([]);
const [offset, setOffset] = useState(1);
const [pageCount, setPageCount] = useState(0)


const deleteassignagent = async (e) => {
   // e.preventDefault();
    //refaddgroupagent.current.click();
    console.log("id>>>>>>>>" + e)
   
    var formdata = new FormData()
    formdata.append('id', e)
  
    const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteAssignlist", {
        method: 'POST',     
        headers: {
            //  'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata
        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.msg,
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: json.msg,
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            showConfirmButton: false,
            timer: 2500
        })
    }


   // setGroupname("NA")
  //  set
   
    getAllPosts()



}
const submitaddagent = async (e) => {
    e.preventDefault();
    //refaddgroupagent.current.click();
    console.log("groupname>>>>>>>>" + groupname)
    console.log("euserid>>>>>>>>" + euserid)
    var formdata = new FormData()
    formdata.append('campid', groupname)
    formdata.append('listid', euserid)
    const response = await fetch(process.env.REACT_APP_IP+"/User/AssignList", {
        method: 'POST',     
        headers: {
            //  'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata
        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.msg,
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: json.msg,
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            showConfirmButton: false,
            timer: 2500
        })
    }


   // setGroupname("NA")
  //  set
   
    getAllPosts()



}


const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

}

const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
}

const getAllPosts = async () => {
    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllAssignlist?pageNumber=${offset - 1}&&size=6`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    const data = res.data;
    const postData = getPostData(data)
    // Using Hooks to set value
    setAllPosts(postData)
    // setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
}
useEffect(() => {
    getAllPosts()
}, [offset])

const getPostData = (data) => {
    return (
        <>

            <div className='row '>

                {/* <AddCampaignform /> */}


                < div className='col-md-12 col-12'>

                    <div className='card' >



                        <div className=''>
                                <div className='card-header text-black'>
                                    <h4 className='text-center'>Existing Assign List</h4>
                                </div>

                            <div className="table-responsive p-1">

                                <table className="table table-hover table-bordered checkoddata ignoreline">
                                    <thead className="bg-darkblue text-white">
                                        <tr>
                                            <th>ID</th>
                                           
                                            <th>List Id | Name</th>
                                            
                                            <th>CampaignId | Campname</th>
                                           
                                            <th>Date Added</th>
                                            

                                            <th>Action</th>
                                            {/* <th>Delete</th> */}


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.content.map(page => {
                                            return (
                                                <tr key={page.id}>
                                                    <td>{page.id}</td>
                                                   
                                                    <td>{page.listid} | {page.listname} </td>
                                                    
                                                    <td>{page.campid} | {page.campname} </td>
                                                   
                                                    <td>{page.dateadded}</td>
                                                    

                                                    <td>
                                                        {/* <button className=" mx-2 btn btn-primary disabled" >
                                                            <i className="fa fa-edit" ></i>
                                                        </button> */}
                                                  <button className='btn btn-danger btn-mysm'>
                                                        
                                                  <i className="fa fa-trash mx-2" onClick={() => deleteassignagent(page.id)}></i>

                                                  </button>
                                                

                                                    </td>





                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                    placeholder="gotopage" onChange={onInputChange} />

                            </div>
                        </div>
                    </div>


                </div>

            </div>


        </>

    )

}


//group option

const optionforgroup = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('groupselectmodel');
    elelist.innerHTML = ""+('<option value="">NA</option>');

    for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | '+json[i]['campname'] + '</option>';
    }
}

useEffect(() => {
    optionforgroup()
}, [])


const optionforlist = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllListname", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('list');
    elelist.innerHTML = ""+('<option value="0">NA</option>');
    for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['id'] +' | '+ json[i]['listname'] + '</option>';
    }

}

useEffect(() => {
    optionforlist()
}, [])





  return (
    <>
 
        <div  style={{ marginTop: "15px" }}>
            <div className='row ' style={{ minHeight: "700px"

                }}>
                     <div className='col-md-3'>
                     
                         <div className='card'>
                              <div className='card-header'>
                                    Assign List To Campaign
                              </div>
                              <div className=''>
                                    <form onSubmit={submitaddagent}>
                                              <div className=" mb-1">
                                                    <b htmlFor="groupname">Select Campaign:</b>
                                                    {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                                    <select id="groupselectmodel" className="form-select mt-2" onChange={(e) => setGroup(e.target.value)}
                                                        required>
                                                        {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                <option value=""></option> */}


                                                    </select>

                                                </div>
                                                {/* <div className="dropdown-divider"></div> */}
                                                <div className="mb-3">
                                                    <label htmlFor="user" className="form-label">Select List</label>
                                                    <select id="list" className="form-select"  onChange={(e) => seteuserid(e.target.value)} required>
                                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                    <option value=""></option> */}
                                                                    


                                                    </select>
                                                </div>
                                                
                                       <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                        </div>


                                    </form>
                              </div>

                         </div>
                     </div>

                     <div className='col-md-9'>
                             
                               {posts}

<div className="row mb-5">
    <div className="col-md-3 col-12"></div>

    <div className="col-md-6 col-12">


        <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            breakLinkClassName={"break-mef"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            forcePage={offset - 1}

            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}

        />


    </div>

</div>
                     </div>


            </div>
        
                  
    
        </div>
    </>
    
  )
}



export default Assiglist